/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
    font-family: 'AirbnbCerealBlack'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealBlack.ttf'); /*URL to font*/
  }

  @font-face {
    font-family: 'AirbnbCerealBold'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealBold.ttf'); /*URL to font*/
  }

  @font-face {
    font-family: 'AirbnbCerealBook'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealBook.ttf'); /*URL to font*/
  }

  @font-face {
    font-family: 'AirbnbCerealExtraBold'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealExtraBold.ttf'); /*URL to font*/
  }

  @font-face {
    font-family: 'AirbnbCerealLight'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealLight.ttf'); /*URL to font*/
  }

  @font-face {
    font-family: 'c'; /*a name to be used later*/
    src: url('assets/fonts/AirbnbCerealMedium.ttf'); /*URL to font*/
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-menu-panel-notification {
  min-width: 450px !important;
  max-width: 500px !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 2px;
  outline: 0;
  height: 430px !important;
}

.action-button{
  font-family: AirbnbCerealBlack;
  font-size: 16px !important;
  padding-top: 20px;
  background-color: #536dfe;
  width: 99%;
  border-radius: 50px;
  height: 60px;
  color: #ffffff !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.action-secondary-button{
  font-family: AirbnbCerealBlack;
  font-size: 16px !important;
  padding-top: 17px;
  border: 2px solid #536dfe !important;
  width: 99%;
  border-radius: 50px;
  height: 60px;
  color: #536dfe !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.button-register-facebook{
  font-family: AirbnbCerealBlack;
  padding-top: 15px;
  border: 2px solid #3b5997 !important;
  width: 100%;
  border-radius: 50px;
  height: 55px;
  color: #3b5997 !important;
  margin-bottom: 20px;
  cursor: pointer;
}

.card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}


.ico-home{
  color: #536dfe;
  font-size: 40px;
  margin-top: 10px;
  cursor: pointer;
}
